<template>
  <div>
    <div class="d-flex align-items-center justify-content-end mt-3 mb-4">
      <!-- 匯出 Excel -->
      <div>
        <b-button
          variant="inverse-primary"
          class="ml-0 ml-xl-4"
          @click="handleExport"
        >
          <span class="mdi mdi-file-excel mr-1"></span>
          匯出 Excel
        </b-button>
      </div>

      <b-input-group class="ml-4" style="width: 320px">
        <b-form-input
          placeholder="搜尋會員名稱、LINE UID、回答內容"
          v-model="query.keyword"
          v-on:keyup.enter="handlePageReset"
        ></b-form-input>
        <b-input-group-append>
          <b-button @click="handlePageReset"
            ><i class="fa fa-search"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-table
      responsive
      striped
      hover
      :items="results"
      :fields="fields"
      @sort-changed="handleSort"
    >
      <template #cell(customer_name)="data">
        {{ data.item.customer.name }}
      </template>
      <template #cell(outer_code)="data">
        {{ data.item.customer.outer_code }}
      </template>
      <!-- <template #cell(arrange)="data">
        <b-button
          class="ml-2"
          variant="inverse-primary"
          v-if="checkPermission([consts.INVOICE_LIST_VIEW])"
          :to="{
            name: 'InvoiceDetail',
            params: {
              invoiceId: data.item.id,
            },
          }"
          ><span class="mdi mdi-eye mr-1"></span>查看
        </b-button>
      </template> -->
      <template #cell(created_at)="data">
        {{ formatDate(data.item.created_at) }}
      </template>
    </b-table>
    <CustomPagination
      :currentPage="query.page"
      :totalRows="totalRows"
      :perPage="query.per_page"
      @page-change="handlePageChange"
      @per-page-change="handlePerPageChange"
    />
  </div>
</template>

<script>
import formApi from "@/apis/form";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";

const initialQuery = {
  page: 1,
  per_page: 10,
  keyword: "",
  sort_by: "created_at",
  order_by: "desc",
};

export default {
  components: {
    CustomPagination,
  },
  data() {
    return {
      totalRows: 0,
      fields: [
        {
          key: "customer_name",
          label: "會員名稱",
          sortable: true,
        },
        {
          key: "outer_code",
          label: "LINE UID",
          sortable: true,
        },
      ],
      results: [],
      query: { ...initialQuery },
      form: {
        title: null,
      },
    }
  },
  methods: {
    async fetchResults () {
      try {
        const response = await formApi.getResults(this.$route.params.id, this.query);
        this.results = response.data.data;
        this.totalRows = response.data.meta.total;
        this.form = response.data.form;

        // 更新 fields，但保留前兩個 fields
        this.fields = this.fields.slice(0, 2).concat(
          this.form.subjects.map((subject) => {
            return {
              key: subject.id,
              label: subject.config.title ?? '無標題',
            };
          })
        );
        this.fields.push({
          key: "created_at",
          label: "新增時間",
          sortable: true,
        });
        console.log(this.results);

        // 把 results.responses 依照 subjects 攤開
        for (let i = 0; i < this.results.length; i ++) {
          let result = this.results[i];
          for (let j = 0; j < result.responses.length; j ++) {
            let response = result.responses[j];
            // 如果 response.value 是陣列，就轉成字串
            if (Array.isArray(response.value)) {
              response.value = response.value.join(', ');
            }
            this.results[i][response.subject_id] = response.value;
          }
        }

        console.log(this.results);
        console.log(this.fields);
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取表單結果錯誤", "error");
      }
    },
    async init() {
      this.$emit('loading');
      this.query = updateQueryFromRoute(
        this.$route,
        this.$store,
        this.query,
      );
      await this.fetchResults();
      this.$emit('done');
    },
    async reset() {
      this.$emit('loading');
      this.$set(this, 'query', { ...initialQuery });
      await this.fetchResults();
      this.$emit('done');
    },
    async runQueryAndUpdateUrl() {
      this.$emit('loading');
      updateUrl(this.query, this.$store, this.$router);
      await this.fetchResults();
      this.$emit('done');
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
      this.runQueryAndUpdateUrl();
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
      this.runQueryAndUpdateUrl();
    },
    async handlePageReset() {
      this.$emit('loading');
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }

      await this.fetchResults();
      this.$emit('done');
    },
    handleExport() {
      formApi.exportResults(this.form.id).then(() => {
        this.$swal({
          title: "成功",
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: "success",
          confirmButtonText: "前往查看",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          }
        });
      });
    }
  },
}
</script>
