<template>
  <div>
    <div class="d-flex align-items-center justify-content-end mt-3 mb-4">
      <!-- 匯出 Excel -->
      <div>
        <b-button
          variant="inverse-primary"
          class="ml-0 ml-xl-4"
          @click="handleExport"
        >
          <span class="mdi mdi-file-excel mr-1"></span>
          匯出 Excel
        </b-button>
      </div>

      <b-input-group class="ml-4" style="width: 320px">
        <b-form-input
          placeholder="搜尋會員名稱、LINE UID、回答內容"
          v-model="query.keyword"
          v-on:keyup.enter="handlePageReset"
        ></b-form-input>
        <b-input-group-append>
          <b-button @click="handlePageReset"
            ><i class="fa fa-search"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-table
      responsive
      striped
      hover
      :items="results"
      :fields="fields"
      @sort-changed="handleSort"
    >
      <template #cell(avatar_url)="data">
        <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
          <b-avatar
            :src="data.item.avatar_url"
            variant="secondary"
            size="2rem"
          ></b-avatar>
        </router-link>
      </template>
      <template #cell(name)="data">
        <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
          {{ data.item.name }}
        </router-link>
      </template>
    </b-table>
    <CustomPagination
      :currentPage="query.page"
      :totalRows="totalRows"
      :perPage="query.per_page"
      @page-change="handlePageChange"
      @per-page-change="handlePerPageChange"
    />
  </div>
</template>

<script>
import formApi from "@/apis/form";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";

const initialQuery = {
  page: 1,
  per_page: 10,
  keyword: "",
  sort_by: "created_at",
  order_by: "desc",
};

export default {
  components: {
    CustomPagination,
  },
  data() {
    return {
      totalRows: 0,
      fields: [
        {
          key: "avatar_url",
          label: "",
          sortable: false,
        },
        {
          key: "name",
          label: "顯示名稱",
          sortable: false,
        },
        {
          key: "outer_code",
          label: "識別編號",
          sortable: false,
        },
        {
          key: "mobile_phone",
          label: "手機",
          sortable: false,
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
      ],
      results: [],
      query: { ...initialQuery },
    }
  },
  methods: {
    async fetchResults () {
      try {
        const response = await formApi.getQuestionnaireRegistrants(this.$route.params.id, this.query);
        this.results = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取表單結果錯誤", "error");
      }
    },
    async init() {
      this.$emit('loading');
      this.query = updateQueryFromRoute(
        this.$route,
        this.$store,
        this.query,
      );
      await this.fetchResults();
      this.$emit('done');
    },
    async reset() {
      this.$emit('loading');
      this.$set(this, 'query', { ...initialQuery });
      await this.fetchResults();
      this.$emit('done');
    },
    async runQueryAndUpdateUrl() {
      this.$emit('loading');
      updateUrl(this.query, this.$store, this.$router);
      await this.fetchResults();
      this.$emit('done');
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
      this.runQueryAndUpdateUrl();
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
      this.runQueryAndUpdateUrl();
    },
    async handlePageReset() {
      this.$emit('loading');
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }

      await this.fetchResults();
      this.$emit('done');
    },
    handleExport() {
      formApi.exportResults(this.form.id).then(() => {
        this.$swal({
          title: "成功",
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: "success",
          confirmButtonText: "前往查看",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          }
        });
      });
    }
  },
}
</script>
